/* ==========================================================================
 * Entries
 * ========================================================================== */

.mass-buttons {
  margin: 5px;

  #selectAll {
    position: relative;
    opacity: initial;
    left: 0;
  }

  span {
    padding: 3px;
  }

  button {
    i {
      font-size: 12px;
    }

    height: 24px;
    line-height: 24px;
    padding: 0 0.5rem;
  }
}

.card-stacked {
  input[type="checkbox"] {
    position: relative;
    opacity: initial;
    left: 0;
  }

  .entry-checkbox {
    margin-right: 10px;
  }
}

.collection {
  margin: 15px 15px 0;

  .collection-item {
    padding: 7px;
    height: 65px;
  }
}

.results {
  display: flex;
  padding: 1rem 1rem 0;
  flex-wrap: wrap;
  justify-content: space-between;

  .nb-results {
    display: inline-flex;
  }

  a {
    color: #444;
  }
}

.pagination {
  ul {
    display: flex;
    margin: 0;
    flex-wrap: wrap;
    justify-content: space-around;

    .prev.disabled,
    .next.disabled {
      display: none;
    }
  }

  li {
    padding: 0;

    &:not(.active) a:hover,
    &:not(.active) a:active,
    &:not(.active) a:focus {
      background-color: #e6e6e6;
    }

    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }

  span,
  a {
    padding: 0 10px;
    height: 30px;
    display: block;
    line-height: 30px;
  }

  .disabled {
    margin-right: 10px;
    margin-left: 10px;
  }

  li.active span {
    padding: 0 10px;
    height: 30px;
    display: block;
    color: #fff;
  }
}

.footer-text {
  margin: 0.7rem 0.5rem;
}

.hidden {
  display: none;
}

.picker__date-display {
  display: none;
}

footer {
  &.page-footer {
    margin-top: 10px;
    padding-top: 0;
  }

  .row {
    margin-bottom: 10px;
  }
}
